<template>
  <div>
    <InputLabel :label="t('pricingModifierConditions.timePeriod')" />
    <CheckboxField
      :name="`modifiers.${modifierIndex}.conditions.timePeriod.isYearly`"
      :label="t('yearly')"
    />
    <div class="flex gap-4">
      <Select
        v-if="!isYearlyField.value.value"
        :model-value="fromYearField.value.value?.toString()"
        :label="t('year')"
        :options="yearOptions"
        class="w-full flex-1"
        @update:model-value="fromYearField.setValue($event ? parseInt($event) : undefined)"
      />
      <Select
        :model-value="fromMonthField.value.value?.toString()"
        :label="t('from')"
        :options="monthOptions"
        class="w-full flex-1"
        @update:model-value="fromMonthField.setValue($event ? parseInt($event) : undefined)"
      />
      <Select
        :model-value="toMonthField.value.value?.toString()"
        :label="t('to')"
        :options="monthOptions"
        class="w-full flex-1"
        @update:model-value="toMonthField.setValue($event ? parseInt($event) : undefined)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import CheckboxField from '@/components/CheckboxField.vue';
import InputLabel from '@/components/InputLabel.vue';
import Select from '@/components/Select.vue';
import {computed} from 'vue';
import {formatDate} from '@/hooks/use-formatted-date.ts';
import {useField} from 'vee-validate';
import {useI18n} from 'vue-i18n';

const props = defineProps<{
  modifierIndex: number;
}>();

const { t } = useI18n();

const monthOptions = computed(() =>
  Array.from({ length: 12 }, (_, i) => ({
    label: formatDate(new Date(0, i), { month: 'long' }),
    value: (i + 1).toString(),
  })),
);
const yearOptions = computed(() => {
  const startYear = 2025;
  return Array.from({ length: 20 }, (_, i) => ({
    label: (startYear + i).toString(),
    value: (startYear + i).toString(),
  }));
});
const isYearlyField = useField<boolean | undefined>(
  `modifiers.${props.modifierIndex}.conditions.timePeriod.isYearly`,
  undefined,
  { initialValue: false }
);
const fromYearField = useField<number | undefined>(
  `modifiers.${props.modifierIndex}.conditions.timePeriod.fromYear`,
);
const fromMonthField = useField<number | undefined>(
  `modifiers.${props.modifierIndex}.conditions.timePeriod.fromMonth`,
);
const toMonthField = useField<number | undefined>(
  `modifiers.${props.modifierIndex}.conditions.timePeriod.toMonth`,
);
</script>

<i18n lang="json">
{
  "en": {
    "yearly": "Yearly",
    "year": "Year",
    "from": "From",
    "to": "To"
  },
  "de": {
    "yearly": "Jährlich",
    "year": "Jahr",
    "from": "Von",
    "to": "Bis"
  }
}
</i18n>
